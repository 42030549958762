import { Fragment, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTitle } from 'react-use';
import Spinner from '../../components/spinner';
import { endOfDay, subDays } from 'date-fns';
import { formatDate, formatDateToTimeZone } from '../../utils/format-date';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import PrevPage from '../../components/ui/prev-page';
import { useActiveWorkspaceSlice } from '../../store/active-workspace-slice';
import { usePrintWorkTimeDetailsSlice } from '../../store/print-worktime-details-slice';
import { useStaffWorkTimeReport } from '../../hooks/use-staff-worktime-report';
import { StaffWorkTimeReportResponse } from '../../interfaces/staff-work-time-report-response';

export interface UserDayReport extends Omit<StaffWorkTimeReportResponse['days'][0], 'clocks'> {
  clock: StaffWorkTimeReportResponse['days'][0]['clocks'][0] | null;
}

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const StaffsWorkTimeSimpleReport = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Staffs Worktime Details`);
  const navigate = useNavigate();
  const query = useQuery();
  const { id } = useParams();
  const fromParam = query.get('from');
  const toParam = query.get('to');
  const statusParam = query.get('status');
  const [startDate, setStartDate] = useState<Date | null>(
    fromParam ? new Date(fromParam) : subDays(new Date(), 7),
  );
  const [endDate, setEndDate] = useState<Date | null>(
    toParam ? new Date(toParam) : endOfDay(new Date()),
  );
  const [deviceImageMetaData, setDeviceImageMetaData] = useState<{
    src: string;
    title: string;
  } | null>(null);
  const from = startDate ? formatDateToTimeZone(startDate, 'y-MM-dd') : null;
  const to = endDate ? formatDateToTimeZone(endDate, 'y-MM-dd') : null;
  const workSpaceSubDomain = useActiveWorkspaceSlice((state) => state.workspace?.sub_domain)!;
  const updatePrintWorkTimeDetailsData = usePrintWorkTimeDetailsSlice((state) => state.updateData);
  const staffWorkTimeReport = useStaffWorkTimeReport(
    workSpaceSubDomain,
    from,
    to,
    statusParam ?? 'active',
  );
  const staffWorkTimeDetailsReport = staffWorkTimeReport.data?.find(
    (s) => s.staff_id === +(id ?? -1)!,
  );
  const daysReport = staffWorkTimeDetailsReport?.days.flatMap((d) => {
    const userDayReport: UserDayReport[] = [];
    if (d.clocks.length) {
      d.clocks.forEach((c) => {
        userDayReport.push({ ...d, clock: c });
      });
    } else {
      userDayReport.push({ ...d, clock: null });
    }
    return userDayReport;
  });
  const allClocks: string[][][] = [];
  (staffWorkTimeDetailsReport?.days ?? []).forEach((report) => {
    const daysClocks: string[][] = [];
    report.clocks.forEach((c) => {
      daysClocks.push([
        `'${formatDate(report.date_ms, 'dd-MM-y')}`,
        `'${c.department_title ?? ''}`,
        `'${c.start ?? ''}`,
        `'${c.end ?? ''}`,
        `'${c.location_title ?? ''}`,
      ]);
    });
    (report?.leaveRequests ?? []).forEach((c) => {
      daysClocks.push([
        `Time off`,
        `'${formatDate(report.date_ms, 'dd-MM-y')}`,
        `'${c.start ?? ''}`,
        `'${c.end ?? ''}`,
        `'${c.reason ?? ''}`,
      ]);
    });
    allClocks.push(daysClocks);
  });
  const csvData = [
    [staffWorkTimeDetailsReport?.staff_name, staffWorkTimeDetailsReport?.staff_email],
    ['', '', '', '', ''],
    ['Date', 'Department', 'Clock-in', 'Clock-out', 'Location'],
    ...allClocks.flat(1),
  ];

  const handlePrint = () => {
    updatePrintWorkTimeDetailsData(staffWorkTimeDetailsReport);
    navigate('/print-worktime-simple');
  };

  const handleImage = (src: string | null | undefined, title: string) => {
    if (!src) return;
    setDeviceImageMetaData({ src, title });
  };

  return (
    <>
      <div className="w-full mx-auto space-y-0 pb-14 print:p-0 print:max-w-none print:w-full">
        <div className="flex justify-between mt-6 print:hidden md:mt-0">
          <PrevPage to="../" />
        </div>
        <div className="flex flex-col items-center justify-center w-full p-4 bg-white border border-gray-100 rounded-md shadow-lg md:p-9 print:shadow-none print:p-0 print:border-none">
          <div className="flex items-center justify-between w-full pb-6 mb-6 font-medium border-b print:pb-4 print:mb-0 print:border-b print:border-b-gray-200">
            <div className="flex flex-col w-full font-medium">
              <span className="capitalize">{staffWorkTimeDetailsReport?.staff_name}</span>
              <p className="mt-2 text-sm font-normal text-gray-400 print:mt-0">
                {staffWorkTimeDetailsReport?.staff_email}
              </p>
              <div className="flex flex-col w-full mt-6 space-y-8 font-medium md:space-y-0 md:space-x-4 md:flex-row md:items-center md:justify-between print:mt-0 print:hidden">
                <div className="flex items-center space-x-4">
                  <CSVLink
                    data={csvData}
                    filename={`${staffWorkTimeDetailsReport?.staff_name}-${from}-${to}.csv`}
                    className="flex items-center justify-center h-12 text-white transition duration-200 rounded w-28 bg-brand-primary hover:bg-brand-primary-dark"
                    target="_blank"
                  >
                    Export
                  </CSVLink>
                  <button
                    className="items-center justify-center hidden h-12 text-white transition duration-200 rounded md:flex w-28 bg-brand-primary hover:bg-brand-primary-dark"
                    type="button"
                    onClick={handlePrint}
                  >
                    Print
                  </button>
                </div>
                <div className="flex flex-col space-y-4 md:space-y-0 md:space-x-4 md:flex-row md:items-center">
                  <DatePicker
                    label="Start date"
                    value={startDate}
                    disableMaskedInput
                    inputFormat="dd MMM y"
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DatePicker
                    label="End date"
                    value={endDate}
                    disableMaskedInput
                    inputFormat="dd MMM y"
                    onChange={(newValue) => {
                      setEndDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </div>
            </div>
          </div>
          {staffWorkTimeReport.isLoading ? (
            <div className="flex items-end justify-center w-full my-9">
              <span className="mr-4">Please wait</span>
              <Spinner size="small" />
            </div>
          ) : (
            <div className="flex flex-col w-full">
              <div className="grid w-full h-12 grid-cols-12 text-xs print:text-[8px] font-medium text-gray-700 bg-gray-100 rounded">
                <div className="flex items-center justify-center h-full col-span-3 text-center md:flex md:col-span-2">
                  <span className="line-clamp-2">Date</span>
                </div>
                <div className="flex items-center justify-center h-full col-span-3 text-center md:flex md:col-span-2">
                  <span className="line-clamp-2">Department</span>
                </div>
                <div className="flex items-center justify-center h-full col-span-3 text-center md:flex md:col-span-2">
                  <span className="line-clamp-2">Clock-in</span>
                </div>
                <div className="flex items-center justify-center h-full col-span-3 text-center md:flex md:col-span-2">
                  <span className="line-clamp-2">Clock-out</span>
                </div>
                <div className="items-center justify-center hidden h-full col-span-2 text-center md:flex md:col-span-2">
                  <span className="line-clamp-2">Location</span>
                </div>
                <div className="items-center justify-center hidden h-full col-span-2 text-center md:flex md:col-span-2">
                  <span className="line-clamp-2">Images</span>
                </div>
              </div>
              <div className="flex flex-col w-full divide-y-[1px] divide-gray-100 print:border-b print:border-b-gray-50">
                {(daysReport ?? []).map((report, i) => {
                  return (
                    <Fragment key={i}>
                      <div className="grid w-full grid-cols-12 text-xs print:text-[8px] font-medium text-gray-700 rounded h-14 print:h-10">
                        <div className="flex items-center justify-center h-full col-span-3 text-center capitalize line-clamp-2 md:col-span-2 md:flex">
                          {formatDate(report.date_ms, 'dd-MM-y')}
                        </div>
                        <div className="flex items-center justify-center h-full col-span-3 text-center capitalize line-clamp-2 md:col-span-2 md:flex">
                          {report?.clock?.department_title}
                        </div>
                        <div className="flex items-center justify-center h-full col-span-3 text-center capitalize line-clamp-2 md:col-span-2 md:flex">
                          {report?.clock?.start}
                        </div>
                        <div className="flex items-center justify-center h-full col-span-3 text-center capitalize line-clamp-2 md:col-span-2 md:flex">
                          {report?.clock?.end}
                        </div>
                        <div className="items-center justify-center hidden h-full col-span-2 text-center capitalize line-clamp-2 md:col-span-2 md:flex">
                          {report?.clock?.location_title}
                        </div>
                        <div className="items-center justify-center hidden h-full col-span-2 space-x-1 text-center capitalize line-clamp-2 md:col-span-2 md:flex">
                          {report.clock?.in_pic_url ? (
                            <button
                              className="underline"
                              onClick={() =>
                                handleImage(
                                  report.clock?.in_pic_url ?? null,
                                  `Clock-in on ${formatDate(report.date_ms, 'dd-MM-y')} ${
                                    report.clock?.start
                                  }`,
                                )
                              }
                            >
                              In
                            </button>
                          ) : null}
                          {report?.clock?.in_pic_url && report?.clock?.out_pic_url ? (
                            <span>/</span>
                          ) : null}
                          {report.clock?.out_pic_url ? (
                            <button
                              className="underline"
                              onClick={() =>
                                handleImage(
                                  report.clock?.out_pic_url ?? null,
                                  `Clock-out on ${formatDate(report.date_ms, 'dd-MM-y')} ${
                                    report.clock?.end
                                  }`,
                                )
                              }
                            >
                              Out
                            </button>
                          ) : null}
                        </div>
                      </div>
                      {(report?.leaveRequests ?? []).map((lr) => {
                        return (
                          <div
                            key={lr.id}
                            className="grid w-full grid-cols-6 text-xs print:text-[8px] font-medium text-gray-400 rounded h-14 print:h-10"
                          >
                            <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2 print:text-[10px]">
                              Time off
                            </div>
                            <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                              {lr.start ? `Start: ${lr.start}` : 'Daily'}
                            </div>
                            <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                              {lr.end ? `End: ${lr.end}` : 'Daily'}
                            </div>
                            <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                              Type: {lr.leave_title}
                            </div>
                            {lr?.leave_title?.toLocaleLowerCase() === 'business leave' && (
                              <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                                Reason: {lr.reason}
                              </div>
                            )}
                            <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                              {lr?.create_pic_url && (
                                <button
                                  className="underline"
                                  onClick={() => handleImage(lr?.create_pic_url ?? null, `Create`)}
                                >
                                  Create
                                </button>
                              )}
                              {lr?.create_pic_url && lr?.update_pic_url && <span>/</span>}
                              {lr?.update_pic_url && (
                                <button
                                  className="underline"
                                  onClick={() => handleImage(lr?.update_pic_url ?? null, `Update`)}
                                >
                                  Update
                                </button>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </Fragment>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StaffsWorkTimeSimpleReport;
