import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import reportsService from '../services/reports-service';
import { StaffWorkTimeReportResponse } from '../interfaces/staff-work-time-report-response';

export function useStaffWorkTimeReport(
  workSpaceSubDomain: string,
  startDate: string | null,
  endDate: string | null,
  status: string,
  configOptions: UseQueryOptions<StaffWorkTimeReportResponse[]> = {},
) {
  return useQuery({
    enabled: Boolean(workSpaceSubDomain) && Boolean(startDate) && Boolean(endDate),
    queryKey: [
      reportsService.staffWorkTimeReportQueryKey,
      startDate,
      endDate,
      status,
      workSpaceSubDomain,
    ],
    queryFn: () =>
      reportsService.getStaffWorkTimeReport(workSpaceSubDomain!, startDate!, endDate!, status!),
    refetchOnWindowFocus: true,
    retry: 2,
    ...configOptions,
  });
}
