import * as yup from 'yup';
import { FILE_SIZE, SUPPORTED_FORMATS } from '../../constants/image';
import {
  addYears,
  isAfter,
  isBefore,
  isEqual,
  isValid,
  setMilliseconds,
  setSeconds,
  subYears,
} from 'date-fns';

const EMAIL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const newFormSchema = yup
  .object({
    first_name: yup
      .string()
      .required('this field is required')
      .min(3, ({ min }) => `must be at least ${min} characters`),
    last_name: yup
      .string()
      .required('this field is required')
      .min(3, ({ min }) => `must be at least ${min} characters`),
    email: yup.string().matches(EMAIL_REGEX, 'Invalid Email').required('this field is required'),
    password: yup
      .string()
      .required()
      .min(6, ({ min }) => `must be at least ${min} characters`),
    mobile: yup
      .number()
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('this field is required'),
    department: yup
      .number()
      .typeError('this field is required')
      .nullable()
      .transform((value) => (value <= 0 ? null : value))
      .required('this field is required'),
    job_id: yup
      .number()
      .typeError('this field is required')
      .nullable()
      .transform((value) => (value <= 0 ? null : value))
      .required('this field is required'),
    yearly_time_off: yup
      .mixed()
      .test('mustHaveValueGreaterThanZero', 'Minimum value is 1', (val, ctx) => {
        const timeOffType = ctx.parent.time_off_type;
        if (timeOffType === 'yearly') {
          if (!val || +val <= 0) {
            return false;
          }
          return true;
        } else {
          return true;
        }
      }),
    // monthly_time_off: yup.number().typeError('Invalid number').min(0, 'Minimum is 0'),
    time_off_type: yup.string().required('this field is required'),
    day_hour: yup
      .string()
      .test('time', 'invalid time', (val) => {
        if (val && !isNaN(+val)) {
          return true;
        }
        return false;
      })
      .required('this field is required'),
    // hour_per_month: yup
    //   .string()
    //   .test('time', 'invalid time', (val, c) => {
    //     if (val && !isNaN(+val)) {
    //       return true;
    //     }
    //     return false;
    //   })
    //   .required('this field is required'),
    image: yup
      .mixed()
      .test('fileFormat', 'Unsupported Format', (files: any) => {
        if (!files?.length) return true;
        let allow = true;
        for (let i = 0; i < files.length; i++) {
          const file = files.item(i) as File;
          if (allow) {
            allow = SUPPORTED_FORMATS.includes(file.type);
          }
        }
        return allow;
      })
      .test('fileSize', 'The file is too large', (files: any) => {
        if (!files?.length) return true;
        return files[0].size <= FILE_SIZE;
      })
      .nullable(),
    employment_status: yup.string().required(),
    official_start_date: yup
      .date()
      .typeError('Please enter a valid Start Date')
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .test('CorrectDate', 'Invalid Date', (val: any) => {
        if (
          isValid(val) &&
          isBefore(val, addYears(new Date(), 100)) &&
          isAfter(val, subYears(new Date(), 35))
        ) {
          return true;
        }
        return false;
      })
      .required('this field is required'),
    mandatory_in_location: yup.boolean().required(),
    show_leaves_details: yup.boolean().required(),
    leaves: yup.array().test('mustHaveValue', 'At least one option is required', (val, ctx) => {
      const timeOffType = ctx.parent.time_off_type;
      if (timeOffType === 'preset' && !(val as [])?.length) {
        return false;
      } else {
        return true;
      }
    }),
    work_days: yup.array().of(
      yup.object().shape({
        monday: yup.boolean(),
        tuesday: yup.boolean(),
        wednesday: yup.boolean(),
        thursday: yup.boolean(),
        friday: yup.boolean(),
        saturday: yup.boolean(),
        sunday: yup.boolean(),
        start: yup
          .date()
          .typeError('Invalid Time')
          .required('This field is required')
          .test('beforeDate', 'Conflict', (val, ctx) => {
            if (!val || !isValid(val)) return false;
            const end = setMilliseconds(setSeconds(ctx.parent.end, 0), 0);
            const selectedDate = setMilliseconds(setSeconds(val, 0), 0);
            if (isAfter(selectedDate, end) || isEqual(selectedDate, end)) {
              return false;
            }
            return true;
          }),
        end: yup
          .date()
          .typeError('Invalid Time')
          .required('This field is required')
          .test('afterDate', 'Conflict', (val, ctx) => {
            if (!val || !isValid(val)) return false;
            const start = setMilliseconds(setSeconds(ctx.parent.start, 0), 0);
            const selectedDate = setMilliseconds(setSeconds(val, 0), 0);
            if (isBefore(selectedDate, start) || isEqual(selectedDate, start)) {
              return false;
            }
            return true;
          }),
      }),
    ),
  })
  .required();

export const updateFormSchema = yup
  .object({
    first_name: yup
      .string()
      .required('this field is required')
      .min(3, ({ min }) => `must be at least ${min} characters`),
    last_name: yup
      .string()
      .required('this field is required')
      .min(3, ({ min }) => `must be at least ${min} characters`),
    email: yup.string().matches(EMAIL_REGEX, 'Invalid Email').required('this field is required'),
    password: yup
      .string()
      .nullable()
      .transform((value) => (value ? value : null))
      .min(6, ({ min }) => `must be at least ${min} characters`),
    mobile: yup
      .number()
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('this field is required'),
    department: yup
      .number()
      .typeError('this field is required')
      .nullable()
      .transform((value) => (value <= 0 ? null : value))
      .required('this field is required'),
    job_id: yup
      .number()
      .typeError('this field is required')
      .nullable()
      .transform((value) => (value <= 0 ? null : value))
      .required('this field is required'),
    yearly_time_off: yup
      .mixed()
      .test('mustHaveValueGreaterThanZero', 'Minimum value is 1', (val, ctx) => {
        const timeOffType = ctx.parent.time_off_type;
        if (timeOffType === 'yearly') {
          if (!val || +val <= 0) {
            return false;
          }
          return true;
        } else {
          return true;
        }
      }),
    // monthly_time_off: yup.number().typeError('Invalid number').min(0, 'Minimum is 0'),
    time_off_type: yup.string().required('this field is required'),
    day_hour: yup
      .string()
      .test('time', 'invalid time', (val) => {
        if (val && !isNaN(+val)) {
          return true;
        }
        return false;
      })
      .required('this field is required'),
    // hour_per_month: yup
    //   .string()
    //   .test('time', 'invalid time', (val, c) => {
    //     if (val && !isNaN(+val)) {
    //       return true;
    //     }
    //     return false;
    //   })
    //   .required('this field is required'),
    image: yup
      .mixed()
      .test('fileFormat', 'Unsupported Format', (files: any) => {
        if (!files?.length) return true;
        let allow = true;
        for (let i = 0; i < files.length; i++) {
          const file = files.item(i) as File;
          if (allow) {
            allow = SUPPORTED_FORMATS.includes(file.type);
          }
        }
        return allow;
      })
      .nullable()
      .test('fileSize', 'The file is too large', (files: any) => {
        if (!files?.length) return true;
        return files[0].size <= FILE_SIZE;
      }),
    employment_status: yup.string().required(),
    official_start_date: yup
      .date()
      .typeError('Please enter a valid Start Date')
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .test('CorrectDate', 'Invalid Date', (val: any) => {
        if (
          isValid(val) &&
          isBefore(val, addYears(new Date(), 100)) &&
          isAfter(val, subYears(new Date(), 35))
        ) {
          return true;
        }
        return false;
      })
      .required('this field is required'),
    mandatory_in_location: yup.boolean().required(),
    show_leaves_details: yup.boolean().required(),
    leaves: yup.array().test('mustHaveValue', 'At least one option is required', (val, ctx) => {
      const timeOffType = ctx.parent.time_off_type;
      if (timeOffType === 'preset' && !(val as [])?.length) {
        return false;
      } else {
        return true;
      }
    }),
    work_days: yup.array().of(
      yup.object().shape({
        monday: yup.boolean(),
        tuesday: yup.boolean(),
        wednesday: yup.boolean(),
        thursday: yup.boolean(),
        friday: yup.boolean(),
        saturday: yup.boolean(),
        sunday: yup.boolean(),
        start: yup
          .date()
          .typeError('Invalid Time')
          .required('This field is required')
          .test('beforeDate', 'Conflict', (val, ctx) => {
            if (!val || !isValid(val)) return false;
            const end = setMilliseconds(setSeconds(ctx.parent.end, 0), 0);
            const selectedDate = setMilliseconds(setSeconds(val, 0), 0);
            if (isAfter(selectedDate, end) || isEqual(selectedDate, end)) {
              return false;
            }
            return true;
          }),
        end: yup
          .date()
          .typeError('Invalid Time')
          .required('This field is required')
          .test('afterDate', 'Conflict', (val, ctx) => {
            if (!val || !isValid(val)) return false;
            const start = setMilliseconds(setSeconds(ctx.parent.start, 0), 0);
            const selectedDate = setMilliseconds(setSeconds(val, 0), 0);
            if (isBefore(selectedDate, start) || isEqual(selectedDate, start)) {
              return false;
            }
            return true;
          }),
      }),
    ),
  })
  .required();
