import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { customToastError } from '../../utils/custom-toast-error';
import toast from 'react-hot-toast';
import staffsService from '../../services/staffs-service';

export function useStaffEmailPinCodeCentralDevice(
  configOptions: UseMutationOptions<any, any, any, any> = {},
) {
  return useMutation({
    mutationFn: (id: number) => staffsService.emailStaffCentralDeviceIdPin({ id }),
    onSuccess: (data) => {
      toast.success(data.message ?? 'Successfully Done');
    },
    onError: (data: AxiosError) => {
      const err = data.response?.data as any;
      customToastError('Error', err?.message ?? 'Sorry there was a problem');
    },
    ...configOptions,
  });
}
