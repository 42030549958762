import { useEffect, useRef, useState } from 'react';
import { useTitle } from 'react-use';
import { XMarkIcon, CheckIcon } from '@heroicons/react/24/solid';
import LockIcon from '~icons/mdi/lock-outline';
import LockOpenIcon from '~icons/mdi/lock-open-variant-outline';
import PencilIcon from '~icons/carbon/edit';
import TrashIcon from '~icons/carbon/trash-can';
import leaveService from '../../services/leave-service';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { formatDateToTimeZone } from '../../utils/format-date';
import { useLeaveRequests } from '../../hooks/use-leave-requests';
import { LeaveReq } from '../../interfaces/calendar-leave-request-interface';
import ChangeStatusModal from './components/change-status-modal';
import ReasonModal from './components/reason-modal';
import { FirebaseMessageType, useFirebaseSlice } from '../../store/firebase-slice';
import EmptyState from '../../components/empty-state';
import Spinner from '../../components/spinner';
import { differenceInDays } from 'date-fns';
import { useDeleteLeaveRequest } from '../../hooks/use-delete-leave-request';
import Confirmation from '../../components/libs/confirmation';
import { customToastError } from '../../utils/custom-toast-error';
import workspaceService from '../../services/workspace-service';
import { Tooltip } from '@mui/material';
import { capitalizeFirstLetter } from '../../utils/capitalize-first-letter';

export interface LeaveReqDetails {
  id: LeaveReq['id'];
  status: LeaveReq['status'];
  leaveId: number | null;
  staffId: number;
}

const LeavesOfAbsences = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Time Off Request List`);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [page, setPage] = useState(1);
  const queryClient = useQueryClient();
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedLeaveId, setSelectedLeaveId] = useState<number | null>(null);
  const firebaseMessage = useFirebaseSlice((state) => state.message);
  const [reqDetails, setReqDetails] = useState<LeaveReqDetails | null>(null);
  const { data: leaveRequests, isLoading: lRIsLoading } = useLeaveRequests(page);
  const deleteLeaveRequest = useDeleteLeaveRequest({
    onSuccess: (data) => {
      toast.success(data?.message);
    },
  });

  useEffect(() => {
    const type = firebaseMessage?.type as FirebaseMessageType | undefined;
    // console.log({ type });
    if (type === 'leaveRequestCreated' || type === 'leaveRequestEdited') {
      queryClient.invalidateQueries([leaveService.leavesRequestsQueryKey]);
    }
  }, [firebaseMessage]);

  const { mutate, isLoading } = useMutation(leaveService.updateLeaveRequest, {
    onSuccess: () => {
      toast.success(`Status Changed.`);
      setReqDetails(null);
      queryClient.invalidateQueries([leaveService.leavesRequestsQueryKey]);
      queryClient.invalidateQueries([workspaceService.dashboardQueryKey]);
    },
    onError(error) {
      customToastError('Error', (error as any).response.data.message ?? 'Sorry there was an error');
    },
  });

  const handleUpdateRequest = (
    id: LeaveReq['id'],
    status: LeaveReq['status'],
    leaveId: LeaveReq['leave_id'],
    staffId: LeaveReq['staff_id'],
  ) => {
    setReqDetails({ id, status, leaveId, staffId });
    openChangeStatusModal();
  };

  const updateLeaveRequest = (status: LeaveReq['status']) => {
    if (isLoading) {
      return;
    }
    if (reqDetails) {
      if (status === 'declined') {
        setReqDetails({
          id: reqDetails.id,
          status: 'declined',
          leaveId: reqDetails.leaveId,
          staffId: reqDetails.staffId,
        });
        openReasonModal();
      } else {
        mutate({
          leave_request_id: reqDetails.id,
          status,
          leave_id: reqDetails.leaveId ?? undefined,
        });
      }
    }
  };

  const handleAcceptRequest = (
    id: LeaveReq['id'],
    status: LeaveReq['status'],
    leaveId: LeaveReq['leave_id'],
    staffId: LeaveReq['staff_id'],
  ) => {
    if (isLoading) {
      return;
    }
    if (leaveId) {
      mutate({ leave_request_id: id, status: 'approved', leave_id: leaveId });
    } else {
      setReqDetails({ id, status, leaveId, staffId });
      openChangeStatusModal();
    }
  };

  const handleRejectRequest = (
    id: LeaveReq['id'],
    leaveId: LeaveReq['leave_id'],
    staffId: LeaveReq['staff_id'],
  ) => {
    setReqDetails({ id, status: 'declined', leaveId, staffId });
    openReasonModal();
  };

  const addReason = (description = '') => {
    if (isLoading) {
      return;
    }
    if (reqDetails) {
      mutate({
        description,
        leave_request_id: reqDetails.id,
        status: reqDetails.status,
        leave_id: reqDetails.leaveId ?? undefined,
      });
    }
  };

  const openReasonModal = () => {
    setShowReasonModal(true);
  };

  const closeReasonModal = () => {
    setShowReasonModal(false);
  };

  const openChangeStatusModal = () => {
    setShowChangeStatusModal(true);
  };

  const closeChangeStatusModal = () => {
    setShowChangeStatusModal(false);
  };

  const handleOpenConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleDeleteLeave = (id: number) => {
    if (deleteLeaveRequest.isLoading) {
      return;
    }
    setSelectedLeaveId(id);
    handleOpenConfirmation();
  };

  const handleConfirmation = (bool: boolean) => {
    if (bool && selectedLeaveId && !deleteLeaveRequest.isLoading) {
      deleteLeaveRequest.mutate(selectedLeaveId);
      setSelectedLeaveId(null);
    }
  };

  const handleNextPage = () => {
    if (leaveRequests && page < leaveRequests.last_page) {
      setPage((oldPage) => oldPage + 1);
      containerRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const handlePrevPage = () => {
    if (leaveRequests && page > 1) {
      setPage((oldPage) => oldPage - 1);
      containerRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  if (lRIsLoading && page === 1) {
    return (
      <div className="flex items-end justify-center w-full my-9">
        <span className="mr-4">Please wait</span>
        <Spinner size="small" />
      </div>
    );
  }

  if (lRIsLoading === false && leaveRequests?.total === 0) {
    return (
      <EmptyState
        path="../employee-work-space/employees/employees-create?redirect=leaves-of-absences"
        buttonName="Add Employee"
        message="No leave requests yet!"
        extraMessage="Your employees submit leave requests using the app. Currently, no one has sent you any leave requests."
        showBtn={false}
      />
    );
  }

  return (
    <>
      <div className="w-full mx-auto space-y-8 pb-14" ref={containerRef}>
        <div className="flex flex-col items-center justify-center w-full p-4 bg-white border border-gray-100 rounded-md shadow-lg md:p-9">
          <div className="flex flex-col w-full pb-6 mb-6 font-medium border-b">
            <span>Time Off Request List</span>
            <p className="mt-2 text-sm font-normal text-gray-400">
              This list provides an at-a-glance summary of leave requests: who submitted the
              request? What time does it start and end? What is the type, reason, and status?
            </p>
          </div>
          <div className="flex flex-col w-full text-gray-700">
            <div className="grid items-center w-full h-16 grid-cols-12 rounded bg-gray-50">
              <div className="flex items-center col-span-6 pl-4 text-sm font-semibold md:flex md:col-span-2">
                Name
              </div>
              <div className="items-center hidden col-span-2 pl-4 text-sm font-semibold md:flex md:col-span-2">
                Date
              </div>
              <div className="items-center hidden col-span-2 pl-4 text-sm font-semibold md:flex md:col-span-2">
                Duration
              </div>
              <div className="items-center hidden col-span-2 pl-4 text-sm font-semibold md:flex md:col-span-2">
                Type
              </div>
              <div className="items-center hidden col-span-2 pl-4 text-sm font-semibold md:flex md:col-span-2">
                Status
              </div>
              <div className="flex items-center col-span-6 pl-4 text-sm font-semibold md:flex md:col-span-2">
                Actions
              </div>
            </div>
            <div className="flex flex-col bg-white divide-y divide-gray-10">
              {leaveRequests?.data.map((leaveRequest) => {
                const diff = differenceInDays(
                  new Date(leaveRequest.end_at),
                  new Date(leaveRequest.start_at),
                );
                let sum = 0;
                for (let index = 0; index <= diff; index++) {
                  sum = sum + 1;
                }
                return (
                  <div
                    key={leaveRequest.id}
                    className={`grid w-full grid-cols-12 ${
                      leaveRequest.is_locked ? 'text-gray-300' : 'text-gray-700'
                    }`}
                  >
                    <div className="flex items-center h-10 col-span-12 pl-4 space-x-2 text-sm font-normal capitalize md:h-20 md:col-span-2">
                      {leaveRequest.is_locked ? (
                        <Tooltip title="Request is locked">
                          <span>
                            <LockIcon
                              className={`w-6 h-6 ${
                                leaveRequest.is_locked ? 'text-gray-300' : 'text-gray-400'
                              }`}
                            />
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Request can be modify">
                          <span>
                            <LockOpenIcon
                              className={`w-6 h-6 ${
                                leaveRequest.is_locked ? 'text-gray-300' : 'text-gray-400'
                              }`}
                            />
                          </span>
                        </Tooltip>
                      )}
                      <span
                        className="capitalize line-clamp-2"
                        style={{
                          wordBreak: 'break-word',
                        }}
                      >
                        {leaveRequest?.staff?.first_name} {leaveRequest?.staff?.last_name}
                      </span>
                    </div>
                    <div className="flex items-center h-10 col-span-12 pl-4 text-sm font-normal capitalize md:h-20 md:col-span-2">
                      {leaveRequest.start_at
                        ? formatDateToTimeZone(leaveRequest.start_at, 'dd-MM-y')
                        : '-'}
                    </div>
                    <div className="flex items-center h-10 col-span-12 pl-4 text-sm font-normal capitalize md:h-20 md:col-span-2">
                      {leaveRequest.all_day === 0
                        ? leaveRequest.start_at && leaveRequest.end_at
                          ? sum <= 1
                            ? `1 Day`
                            : `${sum} Days`
                          : '1 Day'
                        : 'Hourly'}
                      {leaveRequest.all_day === 0
                        ? null
                        : leaveRequest.start_at && leaveRequest.end_at
                        ? ` ${formatDateToTimeZone(
                            leaveRequest.start_at,
                            'HH:mm',
                          )} - ${formatDateToTimeZone(leaveRequest.end_at, 'HH:mm')}`
                        : '-'}
                    </div>
                    <div className="flex items-center h-10 col-span-12 pl-4 text-sm font-normal capitalize md:h-20 md:col-span-2">
                      {leaveRequest?.leave?.title ? leaveRequest?.leave?.title : '-'}
                    </div>
                    <div className="flex items-center h-10 col-span-12 pl-4 text-sm font-normal capitalize md:h-20 md:col-span-2">
                      <button
                        onClick={() =>
                          handleUpdateRequest(
                            leaveRequest.id,
                            leaveRequest.status,
                            leaveRequest.leave_id,
                            leaveRequest.staff_id,
                          )
                        }
                        disabled={
                          deleteLeaveRequest.isLoading ||
                          leaveRequest.staff.time_off_type === 'not_allowed' ||
                          leaveRequest.is_locked
                        }
                        className="flex items-center justify-center text-sm font-medium group disabled:hover:text-none"
                      >
                        <span
                          className={`flex items-center px-2 justify-center capitalize break-all rounded text-xs w-[128px] text-center line-clamp-2 text-white h-10 leading-[14px] ${
                            leaveRequest.status === 'pending' ? 'bg-pending-status' : ''
                          } ${leaveRequest.status === 'declined' ? 'bg-reject-status' : ''} ${
                            leaveRequest.status === 'approved' ? 'bg-green-500' : ''
                          }`}
                        >
                          {leaveRequest.status}
                        </span>
                        <PencilIcon className="w-6 h-6 ml-3 text-gray-400 transition shrink-0 group-disabled:hover:text-gray-200 group-disabled:text-gray-200 hover:text-gray-800" />
                      </button>
                    </div>
                    <div className="flex items-center justify-end h-10 col-span-12 pl-4 space-x-2 text-sm font-normal capitalize 3xl:space-x-4 md:justify-start md:h-20 md:col-span-2">
                      <button
                        type="button"
                        className="w-6 h-6 text-red-400 transition duration-200 ease-in-out hover:text-red-800 disabled:hover:text-red-200 disabled:text-red-200"
                        disabled={
                          leaveRequest.is_locked ||
                          leaveRequest.status !== 'pending' ||
                          leaveRequest.staff.time_off_type === 'not_allowed'
                        }
                        onClick={() =>
                          handleRejectRequest(
                            leaveRequest.id,
                            leaveRequest.leave_id,
                            leaveRequest.staff_id,
                          )
                        }
                      >
                        {leaveRequest.status === 'pending' ? (
                          <XMarkIcon className="w-6 h-6" />
                        ) : null}
                      </button>
                      <button
                        type="button"
                        className="w-6 h-6 text-green-400 transition duration-200 ease-in-out hover:text-green-800 disabled:hover:text-green-200 disabled:text-green-200"
                        disabled={
                          leaveRequest.is_locked ||
                          leaveRequest.status !== 'pending' ||
                          leaveRequest.staff.time_off_type === 'not_allowed'
                        }
                        onClick={() =>
                          handleAcceptRequest(
                            leaveRequest.id,
                            leaveRequest.status,
                            leaveRequest.leave_id,
                            leaveRequest.staff_id,
                          )
                        }
                      >
                        {leaveRequest.status === 'pending' ? (
                          <CheckIcon className="w-6 h-6" />
                        ) : null}
                      </button>
                      <button
                        type="button"
                        className="text-red-400 transition duration-200 ease-in-out hover:text-red-800 disabled:hover:text-red-200 disabled:text-red-200"
                        disabled={deleteLeaveRequest.isLoading || leaveRequest.is_locked}
                        onClick={() => handleDeleteLeave(leaveRequest.id)}
                      >
                        <TrashIcon className="w-6 h-6" />
                      </button>
                    </div>
                    {leaveRequest.reason ? (
                      <div className="flex items-center h-12 col-span-12 pl-2 space-x-2 text-xs font-medium text-left lg:pl-6">
                        <span className="text-yellow-500">Reason:</span>
                        <span className="line-clamp-3">
                          {capitalizeFirstLetter(leaveRequest.reason)}
                        </span>
                      </div>
                    ) : null}
                    {leaveRequest.description ? (
                      <div className="flex items-center h-12 col-span-12 pl-2 space-x-2 text-xs font-medium text-left lg:pl-6">
                        <span className="text-yellow-500">Rejection Reason:</span>
                        <span className="line-clamp-3">
                          {capitalizeFirstLetter(leaveRequest.description)}
                        </span>
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
          <nav
            className="flex items-center justify-between w-full pt-6 bg-white border-t border-gray-200"
            aria-label="Pagination"
          >
            <div className="hidden sm:block">
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{leaveRequests?.from ?? 0}</span> to{' '}
                <span className="font-medium">{leaveRequests?.to ?? 0}</span> of{' '}
                <span className="font-medium">{leaveRequests?.total ?? 0}</span> results
              </p>
            </div>
            <div className="flex justify-between flex-1 sm:justify-end">
              <button
                className="relative py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md w-36 hover:bg-gray-50"
                onClick={handlePrevPage}
              >
                Previous Page
              </button>
              <button
                className="relative py-2 ml-4 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md w-36 hover:bg-gray-50"
                onClick={handleNextPage}
              >
                Next Page
              </button>
            </div>
          </nav>
        </div>
      </div>
      <ReasonModal show={showReasonModal} closeModal={closeReasonModal} addReason={addReason} />
      <ChangeStatusModal
        show={showChangeStatusModal}
        closeModal={closeChangeStatusModal}
        updateLeaveRequest={updateLeaveRequest}
        currentStatus={reqDetails?.status}
        reqDetails={reqDetails}
        setReqDetails={setReqDetails}
      />
      <Confirmation
        show={showConfirmation}
        closeModal={handleCloseConfirmation}
        confirm={handleConfirmation}
      />
    </>
  );
};

export default LeavesOfAbsences;
